// import * as jQuery from "jquery"
import 'jquery';
import * as bootbox from 'bootbox';
// mount modal for error report:
import ReportErrorModal from './report-error-modal.js';
import { removeLoadingAnimation } from './loading-overlay.js';
import { confirmDialog } from './confirm-dialog.js';
// universal caller for server requests
const PAF = {};

// error manager:
PAF.errorManager = (status, call, noReport, noMessage, error) => {
  // always remove loading animation:
  removeLoadingAnimation();
  // first, figure out what kind of response we got:
  console.log('error: ', status, noReport, noMessage, error);
  if (noReport && !noMessage && error) {
    bootbox.alert(error);
  } else if (noReport && noMessage) {
    // do nothing
  } else if (status == 401) {
    // show dialog and offer login:
    const errorMessage =
      'Your session has expired or you are not logged in.' +
      ' Would you like to log back in?';
    const functionOnConfirm = () => {
      window.location.replace(
        `/login?redir=${encodeURIComponent(window.location)}&show_redir_msg=1`,
      );
    };
    confirmDialog(errorMessage, functionOnConfirm);
  } else {
    PAF.reportError(error, call);
  }
};

// adding report error tool:

PAF.reportError = function (error, api_call) {
  // create error modal:
  const reportErrorModal = new ReportErrorModal();
  reportErrorModal.mount('.modal-space', error, api_call);
};

PAF.getProjectName = function () {
  return window.location.href.split('/')[2].split('.')[0];
};

PAF.getFunctionality = function () {
  return window.location.href.split('/').pop().split('?')[0];
};

// function for reading variables from URL
PAF.getUrlVars = function getUrlVars() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};

PAF.WebSocket = function (path) {
  var loc = window.location;
  var new_uri;
  if (loc.protocol === 'https:') {
    new_uri = 'wss:';
  } else {
    new_uri = 'ws:';
  }
  new_uri += `//${loc.host}`;
  new_uri += path;
  return new WebSocket(new_uri);
};

PAF.Query = async function (call, params, noReport = false, noMessage = false, method = null) {
  console.log('noReport? ', noReport, 'noMessage? ', noMessage);
  // create event that lets the timer reset:
  const apiCallEvent = new CustomEvent('apiCallEvent', { detail: 'API Call' });
  document.dispatchEvent(apiCallEvent);
  if (!method) {
    method = 'POST';
    if (typeof params === 'undefined') {
      method = 'GET';
      params = null;
    }
  }

  // console.log(`/api/${call}`, JSON.stringify(params), jQuery.param(params), params);
  const fetchOptions = {
    headers: params
      ? {
        'content-type': 'application/json; charset=utf-8',
      }
      : {},
    body: params ? JSON.stringify(params) : null,
    mode: 'cors',
    method, // *GET, POST, PUT, DELETE, etc.
  };
  const urlParams = PAF.getUrlVars();
  if ('token' in urlParams) {
    fetchOptions.headers['X-Paf-Token'] = urlParams.token;
    fetchOptions.credentials = 'same-origin';
  }
  const response = await fetch(`/api/${call}`, fetchOptions).catch(
    (error_condition) => {
      // make sure to remove loading indicator
      removeLoadingAnimation();
      console.log('error: ', error_condition);
      PAF.errorManager(error_condition); // , call, noReport, noMessage);
    },
  );
  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType.includes('application/json');
  if (!response.ok) {
    console.log('response not ok: ', response.status, response.statusText);
    let errorMessage;
    if (response.status < 500) {
      if (isJson) {
        errorMessage = await response.json();
      } else {
        errorMessage = await response.text();
      }
      PAF.errorManager(
        response.status,
        call,
        noReport,
        noMessage,
        errorMessage,
      );
    } else {
      errorMessage = response.statusText;
      PAF.errorManager(
        response.status,
        call,
        noReport,
        noMessage,
        `${response.status}: ${errorMessage}`,
      );
    }
    return Promise.reject(errorMessage);
  }
  if (!isJson) {
    return response.text();
  }
  return response.json();
};

PAF.getUserInfo = function () {
  if (PAF.user_info) return new Promise(PAF.user_info);
  return PAF.Query('user/v2/getUserInfo', {}, true).then((user) => {
    console.log(user);
    PAF.user_info = user;
    return user;
  });
};

PAF.logout = function () {
  PAF.Query('auth/logout').then(() => {
    window.location.replace(
      `/login?redir=${encodeURIComponent(window.location)}&show_redir_msg=1`,
    );
  });
};

export default PAF;
